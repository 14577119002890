.custom-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  .form-label {
    font-family: "Inter regular";
    letter-spacing: 0.5px;
    color: var(--color-grey2);
  }
  .form-details {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    max-width: calc(
      calc(var(--noOfColumn) * var(--max-field-width)) +
        calc(calc(var(--noOfColumn) - 1) * 2rem)
    );
    .box-width-flex {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 2rem;
      margin: auto;
      > div {
        display: flex;
        flex-wrap: wrap;
        gap: 2rem;
        width: 100%;
      }
      .fv-row {
        width: var(--max-field-width);
        &.half {
          width: calc(50% - 2rem);
        }
        .item {
          display: flex;
          flex-wrap: nowrap;
          select {
            width: auto;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
          input {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
        &.drop-zone-component {
          display: flex;
          flex-direction: column;
          gap: 0.25rem;
          position: relative;
          width: 100%;
          &.half {
            // width: min(40rem, 100%);
            min-width: min(100%, var(--max-field-width));
            width: calc(50% - 1rem);
            max-width: 50%;
          }
          .preview {
            padding: 4px;
            background-color: var(--bs-gray-300);
            display: flex;
            justify-content: center;
            align-items: start;
            gap: 1rem;
            border: var(--kt-toolbar-border-top);
            border-radius: 0.5rem;
            position: relative;
            img {
              max-width: 100%;
              max-height: 15rem;
              margin: auto;
            }
            .close-image {
              position: absolute;
              top: -0.5rem;
              right: -0.5rem;
              cursor: pointer;
            }
          }
          .dropzone {
            padding: 2.1rem;
            background-color: var(--kt-input-solid-bg);
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            // gap: 1rem;
            border: 1px dashed var(--kt-toolbar-border-top);
            border-radius: 0.5rem;
            span {
              color: var(--kt-input-solid-color);
              font-weight: 500;
              text-transform: uppercase;
              width: auto;
              &:active {
                background-color: var(--kt-input-solid-bg);
                border-color: transparent;
              }
            }
            button {
              text-transform: uppercase;
            }
          }
          .view {
            > img {
              margin-top: 1rem;
              width: 6rem;
              height: 6rem;
            }
            > .detail {
              display: flex;
              gap: 1rem;
              background-color: var(--kt-gray-600);
              padding: 0.5rem 1rem;
              margin-top: 1rem;
              border-radius: 0.5rem;
              max-width: 20rem;
              position: relative;
              img {
                width: 2rem;
                height: 2rem;
                &.close {
                  width: 1rem;
                  cursor: pointer;
                }
              }
              div {
                display: flex;
                flex-direction: column;
                width: 75%;
                .title {
                  font-size: 0.9rem;
                  font-family: "Inter Regular";
                  color: var(--color-black);
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                .size {
                  font-size: 0.7rem;
                  font-family: "Inter Regular";
                  color: var(--color-black);
                }
              }
            }
          }
        }
      }
    }
  }
}
.item-detail-section {
  border: var(--kt-toolbar-border-top);
  border-radius: 0.5rem;
  header {
    padding: 1.5rem 2rem;
    background-color: var(--kt-input-solid-bg);
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    color: var(--kt-form-label-color);
    font-family: inherit;
    font-size: 1.2rem;
    font-weight: 500;
    text-transform: uppercase;
  }
  .detail {
    padding: 1.5rem 2rem;
    .title {
      text-transform: uppercase;
      font-family: "Inter Bold";
      font-size: 1rem;
      color: var(--color-grey18);
    }
    .listing {
      font-family: "Inter Regular";
      font-size: 0.9rem;
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;
      gap: 1rem;
      &.learning_points,
      &.urls {
        flex-direction: column;
      }
      span {
        min-width: 12rem;
        padding: 1rem 2.5rem 1rem 1rem;
        border-radius: 0.5rem;
        position: relative;
        font-size: 0.9rem;
        font-family: "Inter Regular";
        &::first-letter {
          text-transform: uppercase;
        }
        &.tags {
          color: var(--color-green1);
          background-color: var(--color-green2);
          border: 1px solid var(--color-green1);
        }
        &.learning_points {
          color: var(--color-red1);
          background-color: var(--color-red2);
          border: 1px solid var(--color-red1);
        }
        &.urls {
          color: var(--color-blue4);
          background-color: var(--color-blue5);
          border: 1px solid var(--color-blue4);
        }
        .close {
          position: absolute;
          right: 0.5rem;
          top: 1rem;
          width: 1.2rem;
          cursor: pointer;
        }
      }
    }
    .error {
      color: var(--color-error);
      font-size: 1rem;
      font-family: "Inter Medium";
    }
    .selection-lists {
      display: flex;
      gap: 3rem;
      width: 100%;
      .items {
        height: 16rem;
        overflow-y: auto;
      }
      .single-selection-box {
        flex: 1;
        min-width: calc(50% - 3rem);
      }
    }
  }
}
.single-selection-box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .items {
    border: var(--kt-toolbar-border-top);
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    span {
      font-family: inherit;
      padding: 0.5rem 1rem;
      text-transform: capitalize;
      cursor: pointer;
      img {
        width: 1.4rem;
      }
      &:first-child {
        padding: 0.8rem !important;
        background-color: var(--color-blue8);
        width: 100%;
        text-align: center;
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
      }
      &:nth-child(2) {
        padding-top: 1.3rem;
      }
      &:last-child {
        padding-bottom: 1.3rem;
      }
    }
  }
}
.jodit-add-new-line,
.jodit-status-bar {
  display: none !important;
}
.jodit-container {
  padding: 0;
}
.jodit-workplace,
.jodit-toolbar__box {
  background-color: transparent !important;
}

.listing-item {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  &.tags {
    flex-direction: row;
  }
  span {
    padding: 1rem;
    border-radius: 0.5rem;
    display: flex;
    gap: 1rem;
    width: fit-content;
  }
  .tags {
    background-color: var(--color-green-2);
    color: var(--color-green-1);
  }
  .learning_points {
    background-color: var(--color-red-2);
    color: var(--color-red-1);
  }
  .urls {
    background-color: var(--kt-primary-light);
    color: var(--kt-primary);
  }
  .documents {
    background-color: var(--kt-primary-light);
    color: var(--kt-primary);
  }
  .close {
    width: 1rem;
    min-width: 1rem;
  }
}

.drop-zone-component.multiple {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  position: relative;
  width: 100%;
  .dropzone {
    align-items: center;
    background-color: var(--color-grey6);
    border: 1px dashed var(--color-grey1);
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    padding: 2rem;
  }
  .view > .detail {
    background-color: var(--color-blue6);
    border-radius: 0.5rem;
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
    max-width: 20rem;
    padding: 0.5rem 1rem;
    position: relative;
    img {
      height: 2rem;
      width: 2rem;
      &.close {
        cursor: pointer;
        width: 1rem;
      }
    }
    div {
      display: flex;
      flex-direction: column;
      width: 75%;
      .title {
        color: var(--color-black);
        font-family: "Inter medium";
        font-weight: 400;
        font-size: 0.9rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .size {
        color: var(--color-black);
        font-family: "Inter medium";
        font-weight: 400;
        font-size: 0.7rem;
      }
    }
  }
}
.customDatePickerWidth,
.customDatePickerWidth > div.react-datepicker-wrapper,
.customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  .customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  input {
  width: 100%;
}
.switch {
  position: absolute;
  display: inline-block;
  width: 54px;
  height: 26px;
  right: 1rem;
  top: 2rem;

  &.no-position {
    position: relative;
    top: unset;
    right: unset;
    .slider {
      cursor: auto;
    }
  }
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    &:before {
      position: absolute;
      content: "";
      height: 22px;
      width: 22px;
      left: 5%;
      bottom: 2px;
      background-color: var(--color-white);
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }
  input:checked + .slider {
    background-color: var(--color-blue1);
  }

  input:focus + .slider {
    box-shadow: 0 0 1px var(--color-blue1);
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(28px);
    -ms-transform: translateX(28px);
    transform: translateX(28px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}
.accordion {
  display: flex;
  flex-direction: column;
  &.dual {
    > div.item {
      font-family: "Inter Regular";
      font-size: 1rem;
      color: var(--color-grey2);
      cursor: pointer;
      &:nth-child(even) {
        background-color: var(--color-grey15);
      }
      &:nth-child(odd) {
        background-color: var(--color-white);
      }
      &.active {
        background-color: var(--color-blue1);
        color: var(--color-white);
      }
      span {
        padding: 1rem;
        display: flex;
      }
    }
  }
}
.payment-settings {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  margin: 0 2rem;
  .single-item {
    border-radius: 0.5rem;
    border: 1px solid var(--color-grey10);
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: var(--color-grey15);
      color: var(--color-grey2);
      padding: 1.2rem;
      font-family: "Inter medium";
      font-size: 1.1rem;
      cursor: pointer;
    }
    .box-width-flex {
      width: max-content;
    }
  }
}
.fv-row {
  position: relative;
  .overlay-text {
    position: absolute;
    bottom: 0.8rem;
    right: 1rem;
    color: var(--color-grey2);
    font-family: "Inter Regular";
    font-size: 0.9rem;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.custom-select {
  display: block;
  position: relative;
  img {
    width: 2rem;
    padding-bottom: 0.1rem;
  }
  .options-list {
    display: flex;
    flex-direction: column;
    gap: 1.3rem;
    position: absolute;
    top: 3.5rem;
    left: 0;
    background-color: var(--color-white);
    border: 1px solid var(--color-grey10);
    border-radius: 0.5rem;
    padding: 2rem 1rem;
  }
}
