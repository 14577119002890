.custom-modal {
  width: calc(100vw - 4px);
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--color-black-2) !important;
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--tw-card-background-color);
  .detail {
    margin: auto;
    background-color: var(--tw-card-background-color);
    padding: 2rem;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    position: relative;
    width: min(400px, 90%);
    &.subscriptions {
      width: min(650px, 90%);
    }
    .variant {
      margin: auto;
      &.confirm {
        border-radius: 100%;
        border: 3px solid var(--color-yellow-1);
        width: 3.5rem;
        height: 3.5rem;
        div {
          color: var(--color-yellow-1);
          text-align: center;
          font-size: 3rem;
          line-height: 1;
        }
      }
      &.error {
        border-radius: 100%;
        border: 3px solid var(--color-red-5);
        width: 3.5rem;
        height: 3.5rem;
        position: relative;
        :first-child {
          position: absolute;
          top: 0.3rem;
          left: 0.3rem;
          color: var(--color-red-5);
          font-size: 2.6rem;
          line-height: 0.5;
          img {
            width: 2rem;
          }
        }
      }
      &.success {
        border-radius: 100%;
        border: 3px solid var(--color-green-4);
        width: 3.5rem;
        height: 3.5rem;
        position: relative;
        :first-child {
          background-color: var(--color-green-5);
          width: 0.3rem;
          height: 0.8rem;
          transform: rotate(130deg);
          margin-top: 1.6rem;
          margin-left: 0.7rem;
        }
        :last-child {
          background-color: var(--color-green-5);
          width: 0.3rem;
          height: 2rem;
          transform: rotate(220deg);
          margin-top: -1.8rem;
          margin-left: 1.6rem;
        }
      }
    }
    .close {
      position: absolute;
      right: 1rem;
      top: 1rem;
      width: 1.5rem;
      cursor: pointer;
    }
    .header {
      font-family: "Inter Medium";
      font-size: 2rem;
      color: var(--color-black);
      text-align: center;
      text-transform: capitalize;
    }
    .body {
      color: var(--color-grey-2);
      text-align: center;
      margin: 2rem 0;
    }
  }
  &.subscription {
    .detail {
      min-width: min(90%, 500px);
      .header {
        font-size: 1.3rem;
      }
      .items {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 1.5rem 0;
        .item {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          width: 100%;
          border: 1px solid var(--color-grey-11);
          border-radius: 0.5rem;
          padding: 0.6rem;
          .code {
            display: flex;
            gap: 0.5rem;
            align-items: center;
          }
        }
      }
    }
  }
  .import {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    background-color: var(--color-white) !important;
    position: relative;
    width: min(1000px, 90%);
    height: auto;
    max-height: min(1000px, 90%);
    overflow-y: auto;
    .close {
      position: absolute;
      right: 1rem;
      top: 1rem;
      width: 1.5rem;
      cursor: pointer;
      z-index: 1;
    }
    .detail {
      width: 100%;
      .multiple {
        color: var(--color-black);
      }
    }
    .single-error {
      color: var(--color-black);
      font-size: 1.1rem;
      font-weight: 400;
      text-transform: capitalize;
    }
    .table-responsive {
      margin: 0 2rem;
    }
    .pagination-component {
      margin: 0 2rem 2rem;
    }
  }
}
