// otf => OpenType
// ttf => TrueType
// woff => Web Open Font Format
// woff2 => Web Open Font Format 2
$fonts: (
  kaisei-tokumin-bold: (
    "Kaisei Tokumin bold",
    "Kaisei Tokumin bold",
    "../fonts/KaiseiTokumin-Bold.ttf",
    "TrueType",
  ),
  kaisei-tokumin-regular: (
    "Kaisei Tokumin regular",
    "Kaisei Tokumin regular",
    "../fonts/KaiseiTokumin-Regular.ttf",
    "TrueType",
  ),
  inter-bold: (
    "Inter bold",
    "Inter bold",
    "../fonts/Inter-Bold.ttf",
    "TrueType",
  ),
  inter-medium: (
    "Inter medium",
    "Inter medium",
    "../fonts/Inter-Medium.ttf",
    "TrueType",
  ),
  inter-regular: (
    "Inter regular",
    "Inter regular",
    "../fonts/Inter-Regular.ttf",
    "TrueType",
  ),
);
@each $fontName, $singleFont in $fonts {
  @font-face {
    font-family: nth($singleFont, 1);
    src: local(nth($singleFont, 2)),
      url(nth($singleFont, 3)) format(nth($singleFont, 4));
  }
  .theme-font-#{$fontName} {
    font-family: nth($singleFont, 1);
  }
}
