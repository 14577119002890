.calendar-view {
  background-color: var(--color-white);
  border-radius: 0.5rem;
  .top-header {
    border-bottom: 1px solid var(--color-grey16);
    padding: 2rem;
    color: var(--color-black);
    font-family: "Inter medium";
    font-size: 1.3rem;
    img {
      margin: 0 1rem;
    }
  }
  .calendar {
    padding: 2rem;
    .fc-toolbar-chunk {
      button,
      button:hover,
      button:active,
      button:focus,
      button:disabled {
        background-color: var(--color-grey22) !important;
        border: 0;
        padding: 0.8rem 2rem;
        color: var(--color-grey2) !important;
        font-family: "Inter medium";
        font-size: 1rem;
        text-transform: capitalize;
        box-shadow: none !important;
        outline: 0;
        &.fc-button-active {
          background-color: var(--color-grey11) !important;
        }
        &.fc-prev-button,
        &.fc-next-button {
          padding: 0.8rem 1.4rem;
        }
      }
      .fc-toolbar-title {
        color: var(--color-black);
        font-family: "Inter medium";
        font-size: 1.2rem;
        font-weight: unset;
      }
    }
    th,
    td {
      .fc-daygrid-day-frame {
        height: 8rem;
      }
      a {
        color: var(--color-black);
        font-family: "Inter regular";
        font-size: 1rem;
        font-weight: 100;
        .event-content {
          text-wrap: auto;
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          margin-bottom: 0.5rem;
          background-color: var(--hslVal);
          border-top-left-radius: 0.5rem;
          border-bottom-left-radius: 0.5rem;
          padding: 0.5rem;
          color: var(--color-white);
        }
      }
    }
  }
}
