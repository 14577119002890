.non-auth-layout {
  height: 100vh;
  width: 100vw;
  background-image: url("../images/general_old/auth_main_image.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  position: relative;
  .content {
    color: var(--color-white);
    position: absolute;
    bottom: 20vh;
    left: 7vw;
    h1 {
      font-size: 4.4rem;
      letter-spacing: -1px;
      color: var(--kt-primary-inverse);
    }
    span {
      letter-spacing: 2px;
      font-size: 1.5rem;
      color: var(--kt-primary-inverse);
    }
  }
  .form-component {
    position: absolute;
    top: 5vh;
    left: 46vw;
    height: 90lvh;
    background-color: var(--kt-primary-inverse);
    border-radius: 0.5rem;
    width: clamp(30rem, 50vw, 600px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: auto;
    img {
      padding-top: 1rem;
    }
    .non-auth-header {
      flex-direction: column;
      text-align: center;
      margin: 2rem 0;
      h2 {
        font-size: 2.5rem;
        // color: var(--kt-primary-inverse);
      }
      span {
        font-size: 1.1rem;
        // color: var(--kt-primary-inverse);
      }
    }
    .form {
      display: flex;
      justify-content: center;
      flex-direction: column;
      // gap: 1rem;
      flex-wrap: wrap;
      width: clamp(10rem, 100%, 20rem);
      .business-item {
        background-color: var(--color-grey6);
        border-radius: 0.5rem;
        padding: 1rem;
        font-size: 1rem;
        margin-bottom: 0.6rem;
      }
    }
    .register {
      padding-bottom: 1rem;
      span {
        color: var(--kt-primary);
        cursor: pointer;
      }
    }
  }
}
#kt_toolbar {
  h1 {
    font-family: "Inter regular";
    font-size: 1.1rem;
    color: var(--color-black);
    letter-spacing: 0.6px;
  }
  &.duplicate {
    top: 10px !important;
    background-color: transparent;
    box-shadow: none;
    border-top: 0 !important;
    width: fit-content;
    li {
      font-size: 0.95rem;
      color: var(--color-grey2);
      font-family: "Inter medium";
      letter-spacing: 0.5px;
      font-weight: 500;
    }
  }
}
#kt_header_user_menu_toggle {
  img {
    width: 4rem;
    &.profile-open {
      width: 1.3rem;
      margin-left: .3rem;
      margin-bottom: .3rem;
    }
  }
}

.listing-component {
  h1 {
    font-size: 1.2rem;
    font-family: "Inter regular";
    color: var(--color-black);
    letter-spacing: 0.6px;
  }
}

.menu-item.logout,
.menu-item.logout:hover {
  position: fixed;
  font-family: "Inter bold";
  font-size: 1rem;
  bottom: 1rem;
  background-color: var(--color-white);
  width: 265px;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  .menu-title {
    justify-content: center;
    color: var(--color-black);
  }
}

#profileDetails {
  .overlay {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--color-black2);
    z-index: 1;
  }
  .detail {
    position: absolute;
    top: 0;
    right: 0;
    background-color: var(--color-grey2_25);
    z-index: 1;
    margin-left: auto;
    width: fit-content;
    padding: 0 2rem 2rem;
    border-right: 1px solid var(--color-grey1);
    background-color: var(--color-white);
    height: 100vh;
    width: 23rem;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    :first-child {
      .close-icon {
        position: absolute;
        right: 1rem;
        top: 1rem;
        width: 1.7rem;
        cursor: pointer;
      }
      .user-role {
        font-family: "Inter Medium";
        color: var(--color-grey2);
        font-size: 1.3rem;
      }
      > div {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid var(--color-grey4);
        padding: 1.2rem 0.3rem;
        flex-direction: column;
        gap: 1rem;
        &:last-child {
          border-bottom: 0;
        }
        &.user-detail {
          .name {
            font-size: 1.1rem;
            font-family: "Inter Medium";
            color: var(--color-black);
          }
          .email {
            font-size: 0.9rem;
            font-family: "Inter Regular";
            color: var(--color-grey2);
          }
        }
        &.user-permission {
          div :first-child {
            color: var(--color-grey2);
            font-family: "Inter Regular";
            font-size: 1.1rem;
          }
          div :last-child {
            color: var(--color-black);
            font-family: "Inter Medium";
            font-size: 1.1rem;
            text-align: end;
          }
        }
        &.switch-business,
        &.reset-password {
          display: flex;
          flex-direction: row;
          justify-content: start;
          align-items: center;
          gap: 1rem;
          img {
            width: 2rem;
          }
          span {
            color: var(--color-grey2);
            font-family: "Inter Regular";
            font-size: 1.1rem;
            + img {
              position: absolute;
              right: 2.5rem;
              width: 1rem;
            }
          }
        }
      }
    }
  }
}
