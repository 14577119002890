@for $i from 10 through 30 {
  .theme-font-size-#{$i} {
    font-size: calc(calc($i / var(--default-font-size)) * 1rem);
  }
}

$badge_variants: (
  custom-green:(
    var(--color-green-6),
    var(--color-white),
  ),
  custom-blue:(
    var(--color-blue13),
    var(--color-white), 
  ),
  custom-grey:(
    var(--color-grey23),
    var(--color-black),
  ),
);

.badge{
  @each $variantName, $singleVariant in $badge_variants {
    &.#{$variantName} {
      background-color: nth($singleVariant, 1) !important;
      color: nth($singleVariant, 2) !important;
    }
  }
}

$button_variants: (
  grey-1: (
    var(--color-grey9),
    var(--color-grey8),
  ),
  white-blue-1: (
    var(--color-white),
    var(--color-blue1),
  ),
  white-blue-10: (
    var(--color-white),
    var(--color-blue10),
  ),
  white-1: (
    var(--color-white),
    var(--color-black),
  ),
  blue-1: (
    var(--color-blue1),
    var(--color-white),
  ),
  blue-2: (
    var(--color-blue2),
    var(--color-white),
  ),
  blue-9: (
    var(--color-blue9),
    var(--color-white),
  ),
  blue-10: (
    var(--color-blue10),
    var(--color-white),
  ),
  red-1: (
    var(--color-red4),
    var(--color-white),
  ),
  red-2: (
    var(--color-red1),
    var(--color-white),
  ),
  orange-1: (
    var(--color-red1),
    var(--color-white),
  ),
  grey-2: (
    var(--color-grey19),
    var(--color-grey20),
  ),
  grey-21: (
    var(--color-grey21),
    var(--color-white),
  ),
  green-1: (
    var(--color-green5),
    var(--color-white),
  ),
);


button,
button:hover,
button:active {
  @each $variantName, $singleVariant in $button_variants {
    &.variant-#{$variantName} {
      background-color: nth($singleVariant, 1) !important;
      color: nth($singleVariant, 2) !important;
      font-family: "Inter medium";
      font-size: 1rem;
      letter-spacing: 0.5px;
    }
  }
  &:disabled {
    opacity: 0.6;
  }
  &.type-1 {
    max-width: 12rem;
  }
  &.type-2 {
    padding-top: 0.6rem !important;
    padding-bottom: 0.6rem !important;
  }
  &.plus::before {
    content: "+";
    font-size: 1.3rem;
    padding-right: 0.4rem;
    line-height: 0;
  }
  &.underline_with_column {
    text-decoration: underline;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 !important;
  }
}
.radioField {
  display: flex;
}
