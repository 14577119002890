.preview {
  display: flex;
  flex-direction: column;
  &.schedule {
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2rem;
    width: 100%;
    .item {
      flex-direction: row;
      width: calc(50% - 1rem);
      min-width: var(--max-field-width);
      max-width: 35rem;
      border: 2px dotted var(--color-grey5) !important;
      border-radius: 0.5rem;
      padding: 0.5rem 1.5rem !important;
      gap: 1rem !important;
    }
  }
  .item {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0;
    gap: 1rem;
    .text-gray-800 {
      font-family: "Inter regular";
      font-size: 1.1rem;
      font-weight: 500;
      color: var(--color-black) !important;
    }
    &:first-child {
      padding: 0;
      border-bottom: 0;
      flex-direction: row;
      gap: 2rem;
      align-items: start;
      img {
        max-width: 20rem;
      }
      .top {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        flex: 1;
        div {
          display: flex;
          gap: 1rem;
          font-size: 1.15rem;
          font-weight: 500;
          &.title {
            .text-gray-800 {
              font-size: 1.2rem;
            }
          }
        }
        &.add-border {
          div {
            padding: 0.6rem;
            border: 2px dotted var(--color-grey5) !important;
            border-radius: 0.5rem;
          }
        }
      }
    }
    &.courses {
      flex-wrap: wrap;
      flex-direction: row;
      gap: 1rem;
      > div {
        background-color: var(--color-grey-1);
        border-radius: 0.5rem;
        padding: 1.5rem;
        width: min(100%, calc(50% - 1rem));
        display: flex;
        flex-direction: column;
        gap: 1rem;
        ul {
          padding-left: 0;
          li {
            content: none;
            display: flex;
            gap: 1rem;
          }
        }
      }
    }
    span,
    li {
      &.fw-bold {
        // color: var(--kt-text-muted) !important;
        color: var(--color-black) !important;
      }
    }
  }
}

.module-detail-view {
  background-color: unset !important;
  box-shadow: none !important;
  padding: 0 !important;
  width: min(100%, calc(3 * var(--max-field-width)));
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  .tab-item-detail {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .detail {
      // font-family: "Inter Regular";
      // font-size: 0.9rem;
      // color: var(--color-black);
      &.document {
        display: flex;
        flex-direction: row;
        background-color: var(--color-blue6);
        padding: 0.5rem 1rem;
        margin-top: 1rem;
        border-radius: 0.5rem;
        max-width: 20rem;
        position: relative;
        img {
          width: 2rem;
        }
        .title {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        div {
          display: flex;
          flex-direction: column;
          width: 85%;
          gap: 0.3rem;
        }
      }
    }
    .courses-list {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      flex-wrap: wrap;
      .single-type {
        background-color: var(--color-grey16);
        padding: 1.5rem;
        border-radius: 0.5rem;
        width: 20rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        .course-item {
          display: flex;
          gap: 0.5rem;
          align-items: center;
          font-size: 1rem;
          font-family: "Inter Regular";
          color: var(--color-black);
        }
      }
    }
  }
  > div {
    background-color: var(--tw-card-background-color);
    box-shadow: 0px 4px 4px 0px var(--tw-card-box-shadow);
    border-radius: 0.5rem;
  }
  .actions-buttons {
    position: absolute;
    top: 1rem;
    right: 1rem;
    display: flex;
    gap: 1rem;
  }
}
hr {
  border: 0;
  border-bottom: var(--tw-card-border);
  opacity: 1;
  &.light {
    border-bottom: 1px solid var(--color-grey16);
  }
}
.course-admin-detail {
  .header-detail {
    display: flex;
    gap: 1rem;
    padding: 0 2rem 1rem;
    border-bottom: 1px solid var(--color-grey10);
    img {
      cursor: pointer;
    }
    .title {
      font-family: "Inter Medium";
      font-size: 1.2rem;
      color: var(--color-black);
    }
  }
  .detail {
    margin: 1.5rem 4rem 0;
    border: 1px solid var(--color-grey10);
    border-radius: 0.5rem;
    background-color: var(--color-grey15);
    .title {
      display: flex;
      padding: 1.4rem 1.4rem 1rem;
      font-family: "Inter Bold";
      font-size: 1rem;
      color: var(--color-black);
      text-transform: uppercase;
    }
    .units-list {
      display: flex;
      flex-direction: column;
      span {
        cursor: auto;
        background-color: var(--color-white);
        padding: 1.4rem;
        font-family: "Inter Regular";
        font-size: 1rem;
        color: var(--color-black);
        border-bottom: 1px solid var(--color-grey10);
        &.no-accordion {
          color: var(--color-grey-2);
          font-size: 1.1rem;
        }
      }
    }
    .learning-points-list {
      display: flex;
      flex-direction: column;
      padding: 0;
      span {
        cursor: auto;
        background-color: var(--color-white);
        padding: 0.8rem;
        font-family: "Inter Regular";
        font-size: 1rem;
        color: var(--color-black);
        &:first-child {
          padding-top: 2rem;
        }
        &:last-child {
          padding-bottom: 2rem;
        }
      }
    }
  }
}
