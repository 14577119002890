.subscriptions {
  h1 {
    font-family: "Inter Medium";
    font-size: 1rem;
    color: var(--color-black);
    padding: 1rem 1.5rem 1.5rem;
  }
  .select-type {
    span {
      background-color: var(--color-grey1);
      color: var(--color-white);
      font-family: "Inter Medium";
      font-size: 1.2rem;
      padding: 1rem 2rem;
      cursor: pointer;
      &.active {
        background-color: var(--color-blue1);
        color: var(--color-white);
      }
      &:first-child {
        border-top-left-radius: 1rem;
        border-bottom-left-radius: 1rem;
      }
      &:last-child {
        border-top-right-radius: 1rem;
        border-bottom-right-radius: 1rem;
      }
    }
  }
  .subscription-items {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
    padding: 3rem 0;
    .item {
      width: 22rem;
      border: 1px solid var(--color-grey11);
      border-radius: 0.5rem;
      padding: 1.5rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .item_details {
        display: flex;
        flex-direction: column;
        .title {
          font-family: "Inter Medium";
          font-size: 1.2rem;
          color: var(--color-black);
        }
        .credits {
          font-family: "Inter Bold";
          font-size: 2.3rem;
          color: var(--color-black);
          padding-bottom: 1rem;
          border-bottom: 1px solid var(--color-grey11);
          span {
            margin-left: 0.5rem;
            font-size: 0.8rem;
            font-family: "Inter Medium";
            color: var(--color-black);
          }
        }
        .subscription_detail {
          padding: 1rem 0;
          font-family: "Inter Regular";
          font-size: 0.9rem;
          color: var(--color-black);
        }
      }
    }
  }
}

.auto_charge_cancel_requests {
  background-color: var(--color-white);
  padding: 2rem 1rem !important;
  border-radius: 0.5rem;
  width: clamp(400px, 90%, 900px);
  position: relative;
  .close {
    position: absolute;
    right: 1rem;
    top: 1rem;
    width: 1.5rem;
  }
  h2 {
    text-align: center;
  }
}
