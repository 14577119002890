.wallet {
  .top {
    display: flex;
    img {
      max-width: 20rem;
      padding: 2rem 0.5rem 0.5rem;
      background-color: var(--color-white);
      border-radius: 0.5rem;
    }
    .detail {
      background-color: var(--color-white);
      display: flex;
      flex-direction: column;
      > * {
        padding: 0.5rem 1rem;
        border-bottom: 1px solid var(--color-grey11);
      }
      .wallet-header {
        display: flex;
        justify-content: space-between;
        span {
          display: flex;
          gap: 1rem;
          padding: 1rem;
        }
      }
    }
  }
}
