:root {
  --default-font-size: 16;
  --max-field-width: 28rem;
  --single-grid-item-width: 28rem;
  --color-green-1: #16801d;
  --color-green-2: #e7f6e6;
  --color-green-3: #44905b;
  --color-green-4: #b8eac7;
  --color-red-1: #ff5938;
  --color-red-2: #ffb4a52e;
  --color-red-3: #ffd4cc;
  --color-grey-1: #f6f6f6;
  --tw-primary-active: #056ee9;
  --tw-gray-900: #07143;
  --tw-gray-700: #4b5675;
  --tw-gray-200: #f1f1f4;
  --tw-gray-300: #dbdfe9;
  --tw-card-box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);
  --tw-card-background-color: white;
  --tw-card-border: 1px solid var(--tw-gray-200);
  --color-black: black;
  --color-black-2: #00000066;
  --color-yellow-1: #f6c000;
  --color-red-5: #f8285abf;
  --color-green-5: #17c653;
  --color-green-6: #85BC5A;
  --color-grey-2: #636674;
  --color-grey-11: #dddddd;

  --color-grey1: #b6b6b6;
  --color-grey2: #636674;
  --color-grey2_25: #63667440;
  --color-grey3: #fcfcfc;
  --color-grey4: #e4e4e4;
  --color-grey5: #cfcfcf;
  --color-grey6: #f7f7f7;
  --color-grey7: #4b5675;
  --color-grey8: #727272;
  --color-grey9: #e8e6e6;
  --color-grey10: #efefef;
  --color-grey11: #dddddd;
  --color-grey12: #f7f2ff;
  --color-grey13: #1e1e2d;
  --color-grey14: #9899ac;
  --color-grey15: #f9f8f8;
  --color-grey16: #f6f6f6;
  --color-grey17: #99a1b7;
  --color-grey18: #353f5c;
  --color-grey19: #f1f1f1;
  --color-grey20: #797979;
  --color-grey21: #938e6f;
  --color-grey22: #F2F2F2;
  --color-grey23: #ECECEC;
  --color-blue1: #672ebb;
  --color-blue2: #4472c4;
  --color-blue3: #672ebb;
  --color-blue4: #3c87cc;
  --color-blue5: #5496d236;
  --color-blue6: #dbe7f3;
  --color-blue7: #219ed3;
  --color-blue8: #a48bc7;
  --color-blue9: #2ebbbb;
  --color-blue10: #2e85bb;
  --color-blue11: #e9f3ff;
  --color-blue12: #1b84ff;
  --color-blue13: #5978C7;
  --color-white: white;
  --color-black: black;
  --color-black1: #09071a;
  --color-black2: #00000066;
  --color-black3: #252f4a;
  --color-black_30: #00000008;
  --color-error: red;
  --color-green1: #16801d;
  --color-green2: #e7f6e6;
  --color-green3: #44905b;
  --color-green4: #b8eac7;
  --color-green5: #17c653;
  --color-red1: #ff5938;
  --color-red2: #ffb4a52e;
  --color-red3: #ffd4cc;
  --color-red4: #f8285a;
  --color-red5: #f8285abf;
  --color-yellow1: #f6c000;
  --color-orange1: #ff6e39;
  --color-orange2: #ffe3ae;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
/* Track */
::-webkit-scrollbar-track {
  background: var(--kt-gray-600);
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color-blue1);
  border-radius: 0.5rem;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--color-blue1);
}
