.transaction_logs {
  padding-bottom: 5rem;
  .type {
    display: flex;
    gap: 1rem;
    border-bottom: var(--kt-toolbar-border-top);
    padding: 1.5rem 2rem;
    span {
      color: var(--color-grey2);
      font-family: "inter medium";
      font-size: 1.1rem;
      cursor: pointer;
      &.active {
        color: var(--color-blue1);
      }
    }
  }
  .filters {
    padding: 1.2rem 2rem;
    border-bottom: var(--kt-toolbar-border-top);
  }
  .logs {
    padding: 1.5rem;
    display: flex;
    gap: 2rem;
    .users_list {
      border-right: var(--kt-toolbar-border-top);
      padding-right: 2rem;
      display: flex;
      flex-direction: column;
      gap: 0.7rem;
      .specific_logs,
      .user {
        padding: 0 1rem;
        font-size: 1.1rem;
        font-family: "inter regular";
        color: var(--color-black1);
        text-transform: capitalize;
        cursor: pointer;
        &.active{
          color: var(--color-blue1);
        }
      }
    }
    .logs_list {
      display: flex;
      flex-direction: column;
      gap: 2.5rem;
      .single_day {
        display: flex;
        gap: 1rem;
        .line {
          .vertical {
            border-left: var(--kt-toolbar-border-top);
            width: 0px;
            height: calc(100% - 1.5rem);
            margin: 0.5rem auto;
          }
        }
        .log_items {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          .single_item * {
            font-size: 1.05rem;
            font-family: "inter regular";
            color: var(--color-black1);
            .user_name {
              font-family: "inter bold";
            }
            .price {
              color: var(--color-blue1);
            }
          }
          span {
            font-size: 1rem;
            font-family: "inter regular";
            color: var(--color-black1);
            &.date {
              color: var(--color-grey2);
              font-family: "inter medium";
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }
}
