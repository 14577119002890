.loader-component {
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--color-grey2_25);
  height: 100vh;
  width: calc(100vw - 4px);
  z-index: 1000;
}
.spinner-border {
  margin-left: 0.5rem;
  --bs-spinner-border-width: 0.2rem;
  width: 1.2rem;
  height: 1.2rem;
  &.center {
    position: absolute;
    top: 50%;
    left: 50%;
  }
}
.text-hover-blue-1:hover {
  color: var(--color-blue1) !important;
}
.rotate-180 {
  transform: rotate(180deg);
}
.stepper-nav {
  flex-wrap: nowrap !important;
  overflow-x: scroll;
  margin: 0 !important;
  .stepper-item {
    font-family: "Inter regular";
    letter-spacing: 0.5px;
    .svg-icon-2 {
      margin-right: 0.4rem;
    }
    &.current {
      .svg-icon-2 {
        color: var(--color-blue1);
      }
      &::after {
        background-color: var(--color-blue1) !important;
      }
      .stepper-title {
        color: var(--color-blue1) !important;
      }
    }
  }
}

.list-grid-views {
  margin-left: 0.7rem;
  align-items: center;
  line-height: 1;
  display: flex;
  align-self: center;
  border-radius: 0.375rem;
  height: 2.5rem;
  padding: 0.25rem;
  gap: 0.25rem;
}
.create-title {
  font-size: 1.2rem;
  font-family: "Inter medium";
  color: var(--color-black);
}

.schedule-container {
  border: 1px solid #dee2e6;
  border-radius: 0.5rem;
  width: 100%;
  margin-bottom: 1.5rem;
  .heading {
    display: flex;
    align-items: center;
    gap: 1rem;
    border-radius: 0.5rem;
    background-color: var(--kt-input-solid-bg);
    padding: 1rem;
  }
  h4 {
    margin-top: 0.5rem;
    font-size: 1.5rem;
    font-weight: 600;
    color: #343a40;
  }
  .selected-courses-list{
    font-weight:500;
    font-size: 14px;
     .table tr td{
      padding: 16px 18px !important;
     }
    }
  .select-row {
    display: flex;
    align-items: center;
    justify-content: start; // Align items
    margin-bottom: 1rem; // Space between rows
    padding: 1.5rem;
    .select-label {
      flex: 1;
      margin-right: 1rem; // Spacing between label and select
    }

    .select-component {
      flex: 2; // Adjust the width
    }

    .remove-button {
      margin-left: 1rem; // Spacing for the remove button
      color: #dc3545; // Bootstrap danger color for remove
      cursor: pointer; // Cursor for clickable
    }
  }

  .add-schedule-button {
    margin-top: 1rem; // Spacing above the button
    // width: 100%; // Full width for button
    background-color: #ff6b6b; // Custom color for the button
    color: white; // Text color
    border: none; // Remove border
    padding: 0.75rem 1.5rem; // Button padding
    border-radius: 0.5rem; // Rounded corners
    transition: background-color 0.3s ease; // Transition for hover
    white-space: nowrap; 

    &:hover {
      background-color: #ff4d4d; // Darker on hover
    }
  }
}

.stepper.stepper-links .stepper-nav {
  flex-wrap: nowrap;
  overflow-x: auto;
}
.flatpickr-calendar {
  &::before,
  &::after {
    display: none;
  }
  .flatpickr-day {
    &.selected {
      background: var(--color-blue1);
      border-color: var(--color-blue1);
    }
  }
  .flatpickr-weekday {
    color: var(--color-white);
    font-family: "Inter regular";
    font-weight: 100;
  }
  .flatpickr-month,
  .flatpickr-weekdays,
  .flatpickr-monthDropdown-months,
  .flatpickr-monthDropdown-month,
  .flatpickr-weekday {
    background-color: var(--color-blue1) !important;
  }
}
body:has(.hide-calendar-year) {
  .flatpickr-months .flatpickr-month,
  .flatpickr-current-month {
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .flatpickr-weekdays,
  .numInputWrapper,
  .flatpickr-prev-month,
  .flatpickr-next-month {
    display: none;
  }
}
.season-orange {
  color: var(--color-red1);
}
.starts-in-size {
  font-size: 1.2rem;
  color: var(--color-blue2);
}

.border-grey1 {
  border: 1px solid var(--color-grey2_25);
}

.flex-1 {
  flex: 1;
}
