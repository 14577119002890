.pagination-component {
  .pagination {
    li {
      a {
        background-color: var(--color-white);
      }
      &.active {
        a {
          background-color: var(--color-blue1);
        }
      }
    }
  }
  > div {
    border-radius: 0.5rem;
    .pagination_select {
      margin-bottom: 0 !important;
      select.form-select.form-select-solid {
        background-color: var(--color-white) !important;
        margin: 0.6rem 1rem;
        padding: 0;
        border: 0;
      }
    }
  }
}
table {
  thead {
    td {
      font-family: "Inter regular";
      font-size: 1rem !important;
      color: var(--color-grey2) !important;
      letter-spacing: 0.5px;
      white-space: nowrap;
    }
  }
  tbody {
    td {
      font-family: "Inter medium";
      font-size: 1.04rem !important;
      color: var(--color-black) !important;
      letter-spacing: 0.5px;
      white-space: nowrap;
    }
  }
}
.item-status {
  padding: 0.3rem 1rem !important;
  border-radius: 0.5rem;
  text-align: center;
  // width: 7rem;
  width: fit-content;
  text-transform: capitalize;
  &.orange {
    color: var(--color-red1);
    background-color: var(--color-red3);
  }
  &.green {
    color: var(--color-green3);
    background-color: var(--color-green4);
  }
  &.orange1 {
    color: var(--color-orange1);
    background-color: var(--color-orange2);
  }
  &.grey {
    color: var(--color-grey20);
    background-color: var(--color-grey19);
  }
  &.blue {
    color: var(--color-blue12);
    background-color: var(--color-blue11);
  }
}
.auto-renew {
  text-transform: capitalize;
  padding: 0.3rem 1rem !important;
  font-family: "Inter Medium";
  font-size: 1rem;
  &.red {
    color: var(--color-red1);
  }
  &.green {
    color: var(--color-green3);
  }
}
.tags-list {
  padding: 0 !important;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  .tags {
    padding: 0 !important;
    display: flex;
    gap: 0.5rem;
    span {
      padding: 0 !important;
      text-transform: capitalize;
      &:not(:last-child)::after {
        content: ",";
      }
    }
  }
  .more {
    border-radius: 0.6rem;
    padding: 0.2rem 0.4rem !important;
    color: var(--color-green3) !important;
    background-color: var(--color-green4) !important;
  }
}
.load-more {
  margin: auto;
  font-family: "Inter Medium";
  font-size: 1rem;
  color: var(--color-blue1);
  text-transform: uppercase;
  text-decoration: underline;
  cursor: pointer;
}
.table-actions {
  span {
    width: max-content;
    cursor: pointer;
  }
  > img {
    cursor: pointer;
  }
  .actions-list {
    position: absolute;
    top: 1rem;
    background-color: var(--color-white);
    padding: 0;
    right: 1.5rem;
    display: flex;
    flex-direction: column;
    border-radius: 0.5rem;
    width: max-content;
    box-shadow: 0px 4px 2px var(--color-grey12);
    z-index: 1;
    span {
      width: 100%;
      padding: 1.3rem 2rem;
      border-bottom: 1px solid var(--color-grey11);
      font-family: "Inter medium";
      font-size: 1rem;
      color: var(--color-grey2);
      &:hover {
        background-color: var(--color-grey12);
      }
      &:last-child {
        border-bottom: 0;
      }
    }
  }
}
