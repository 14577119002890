.enrollment {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  .top {
    display: flex;
    gap: 2rem;
    > img {
      max-width: 20rem;
      max-width: 20rem;
      background-color: white;
      padding: 1rem;
      border-radius: 0.5rem;
    }
    .detail {
      background-color: white;
      display: flex;
      gap: 1.5rem;
      padding: 3rem 1rem;
      border-radius: 1rem;
      flex: 1;
      .column {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        > div {
          padding: 0.6rem;
          border: 2px dotted var(--color-grey5) !important;
          border-radius: 0.5rem;
          display: flex;
          flex-direction: row;
          gap: 2rem;
          .text-grey-800 {
            font-family: "Inter regular";
            font-size: 1.1rem;
            font-weight: 500;
            color: var(--color-black) !important;
            &.fw-bold {
              color: var(--color-black);
            }
          }
        }
      }
    }
  }
}
.enrollment-details {
  width: 100%;
  .details {
    img {
      align-self: flex-end;
    }
    display: flex;
    gap: 2rem;
    background-color: white;
    padding: 2rem 2rem 0;
    margin-bottom: 1.5rem;
    position: relative;
    .outline {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding-top: 1.5rem;
      h2 {
        font-family: "Inter bold";
        font-size: 1.45rem;
        color: var(--color-black);
      }
      p {
        font-family: "Inter regular";
        font-size: 1.15rem;
        color: var(--color-grey2);
      }
      button {
        position: absolute;
        right: 2rem;
        top: 2rem;
      }
    }
  }
}
.notes_description {
  font-size: 1.1rem;
}
.notes_link {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  a,
  a:hover,
  a:active,
  a:focus {
    color: var(--color-blue4);
    font-family: "Inter regular";
    font-size: 1.1rem;
  }
}
.notes_docs {
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap;
  gap: 1.5rem;
  svg {
    width: 4rem;
  }
}
