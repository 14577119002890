#grid-view {
  width: 100%;
  margin: auto;
  display: flex;
  margin-top: 1rem;
  background-color: transparent;
  box-shadow: none;
  align-items: center;
  .grid-view {
    margin: 1rem auto;
    display: flex;
    width: calc(
      calc(var(--rowItems) * var(--single-grid-item-width)) +
        calc(var(--rowItems) * 2rem) - 2rem
    );
    justify-content: start;
    flex-direction: row !important;
    flex-wrap: wrap;
    gap: 2rem;

    .custom-card {
      display: flex;
      flex-direction: column;
      box-shadow: var(--tw-card-box-shadow);
      background-color: var(--tw-card-background-color);
      border-radius: 0.75rem;
      border: 1px solid var(--color-grey11) !important;
      width: var(--single-grid-item-width);
      position: relative;
      .table-actions {
        position: absolute;
        right: 1rem;
        top: 1.5rem;
        .actions-list {
          position: absolute;
          top: 1rem;
          background-color: var(--color-white);
          padding: 0;
          right: 0;
          display: flex;
          flex-direction: column;
          border-radius: 0.5rem;
          width: max-content;
          span {
            width: 100%;
            padding: 1.3rem 2rem;
            border-bottom: 1px solid var(--color-grey11);
            font-family: "Inter medium";
            font-size: 1rem;
            color: var(--color-grey2);
            &:hover {
              background-color: var(--color-grey12);
            }
            &:last-child {
              border-bottom: 0;
            }
          }
        }
      }
      .grid-header {
        padding: 1.5rem;
        background-color: var(--color-grey12) !important;
        border-bottom: 1px solid var(--color-grey11) !important;
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
        .title {
          color: var(--color-black) !important;
          margin-bottom: 1px;
          font-size: 1.2rem;
          line-height: 1.8rem;
          font-weight: 500;
          font-family: "Inter medium";
        }
        .is_free {
          text-transform: uppercase;
          font-weight: 500;
          font-family: "Inter medium";
          &.green {
            color: var(--color-green-3);
          }
          &.red {
            color: var(--color-red-1);
          }
        }
      }
      &.type-1 {
        .grid-header {
          background: transparent !important;
          border: 0 !important;
          padding-bottom: 0 !important;
          text-align: center;
        }
      }
      &.type-2 {
        .card-body {
          padding: 0;
          .title {
            margin: auto;
          }
          .grid-body {
            padding: 0;
            .single-option {
              padding: 0.8rem 1.5rem;
            }
          }
        }
        .tags {
          span {
            color: var(--kt-text-gray-700) !important;
          }
        }
      }
      .card-body {
        flex-grow: 1;
        padding-bottom: 1.875rem;
        display: grid;
        .grid {
          display: flex;
          flex-direction: column;
          gap: 0rem;
          .single-option {
            padding: 0.8rem 0;
            display: flex;
            align-items: center;
            justify-content: start;
            border-bottom: 1px solid var(--color-grey11);

            div {
              flex: 1;
              &.key {
                text-transform: uppercase;
              }
              &.value {
                text-transform: capitalize;
              }
            }
            &:last-child {
              border: 0;
            }
          }
        }
        .grid-body {
          padding: 1.5rem;
        }
      }
    }
  }
}
.grid {
  display: grid;
}
.grid-border {
  border: 1px dashed var(--tw-gray-300);
}
